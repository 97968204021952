import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './styles';
import { Link } from 'gatsby';
import get from 'lodash/get';

function RoundedSimpleCards(props) {
  const {
    colorTheme,
    cards,
  } = props;

  console.log(props);
  const themeStyles = styled.getThemeStyles(colorTheme);

  return (
    <styled.Wrapper colorTheme={colorTheme}>
      <styled.Inner colorTheme={colorTheme}>
        <styled.Content>
          <styled.Cards>
            {cards.map(card => (
              <styled.Card>
                <styled.Image
                  fluid={get(card, 'image.asset.fluid')}
                />
                <styled.CardData>
                  <styled.CardTitle level={300}>{card.title}</styled.CardTitle>
                  {card.label ? <styled.Link level={500}><Link
                    to={get(card, 'route.slug.current') || card.link}>{card.label} ></Link></styled.Link> : null}
                </styled.CardData>
              </styled.Card>
            ))}
          </styled.Cards>
        </styled.Content>
      </styled.Inner>
    </styled.Wrapper>
  );
}

RoundedSimpleCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
    link: PropTypes.string,
    route: PropTypes.shape({
      slug: PropTypes.shape({
        current: PropTypes.string,
      }),
    }),
  })),
  colorTheme: PropTypes.oneOf(['dark-blue', 'green', 'orange', 'dark-purple', 'white']),
};

RoundedSimpleCards.defaultProps = {
  colorTheme: 'dark-blue',
};

export default RoundedSimpleCards;
