import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { Body, Heading as UnstyledHeading } from '@components/type';
import { ImageContainer } from '../../SearchHit/styles';
import UnstyledImage from 'gatsby-image';

export const Content = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;

  ${mq.gtsm`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: ${H_PADDING_MOBILE};
  `}
`;

export const Cards = styled(Body)`
  margin-bottom: 32px;
  white-space: pre-line;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;

  ${mq.gtmd`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const Card = styled.div`
  background-color: ${(props) => getThemeStyles(props.colorTheme).background};
  aspect-ratio: 1 / 1; /* Makes height equal to width */
  position: relative;
  opacity: ${(props) => props.transparent ? 0.5 : 1};

`;

export const CardData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 24px;
  height: 100%;
  z-index: 1;
  position: relative;
  flex-direction: column;
`;

export const Image = styled(UnstyledImage)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  img {
    filter: brightness(70%) contrast(120%);
  }
`;

export const CardTitle = styled(UnstyledHeading)`
  color: ${colors.N0};
  text-transform: uppercase;
  text-align: center;
  width: 100%;
`;

export const Link = styled(UnstyledHeading)`
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-top: 24px;

  a {
    color: ${colors.N0};
  }
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
  text-transform: uppercase;
  color: ${(props) => props.outline && props.outline !== 'none' ?
    getThemeStyles(props.colorTheme).background :
    getThemeStyles(props.colorTheme).text};

  ${mq.gtsm`
    margin-bottom: 40px;
  `}
`;

export const Inner = styled.div`
  position: relative;
  z-index: 1;
  padding: 18px ${H_PADDING_MOBILE};

  ${mq.gtmd`
    padding: 0 calc((100vw - 1280px) / 2);
  `}
  & > svg {
    position: absolute;
    top: 20%;
    right: -200px;
    left: -100px;
    width: calc(100% + 300px);
    z-index: 0;

    ${mq.gtsm`
      top: ${props => props.flipped ? 'auto' : '0'};
      bottom: ${props => props.flipped ? '0' : 'auto'};
      right: 50%;
      left: 0;
      width: 50%;
    `}
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;

  & > svg {
    z-index: 0;
  }

  & > svg:first-of-type {
    fill: ${(props) =>
      props.outline && props.outline !== 'none' ? 'none' : getThemeStyles(props.colorTheme).background};
    stroke: ${(props) =>
      props.outline === 'top' || props.outline === 'both'
        ? getThemeStyles(props.colorTheme).outline
        : 'none'};
    stroke-width: ${(props) =>
      props.outline === 'top' || props.outline === 'both' ? '3px' : 0};
    vertical-align: bottom;

    ${mq.gtsm`
      stroke-width: 1px;
    `}

    ${(props) =>
      props.outline && props.outline !== 'none' &&
      css`
        width: calc(100% + 6px);
        margin-right: -3px;
        margin-bottom: -3px;
        margin-left: -3px;
      `}
  }

  & > svg:last-of-type {
    fill: ${(props) =>
      props.outline && props.outline !== 'none' ? 'none' : getThemeStyles(props.colorTheme).background};
    stroke: ${(props) =>
      props.outline === 'bottom' || props.outline === 'both'
        ? getThemeStyles(props.colorTheme).outline
        : 'none'};
    stroke-width: ${(props) =>
      props.outline === 'bottom' || props.outline === 'both' ? '3px' : 0};
    transform: scale(-1, -1) translateY(1px);
    vertical-align: top;

    ${mq.gtsm`
      stroke-width: 1px;
    `}

    ${(props) =>
      props.outline &&
      css`
        width: calc(100% + 6px);
        margin-right: -3px;
        margin-top: -1px;
        margin-left: -3px;
      `}
  }
`;

export function getThemeStyles(theme) {
  switch (theme) {
    case 'white':
      return {
        outline: colors.B500,
        background: colors.B500,
        swirl: colors.B100,
        fill: colors.B200,
        text: colors.B500,
      };
    case 'blue':
      return {
        outline: colors.B500,
        background: colors.B500,
        fill: colors.B200,
        text: '#fff',
      };
    case 'purple':
      return {
        outline: colors.P500,
        background: colors.P500,
        fill: colors.P200,
        text: '#fff',
      };
    case 'green':
      return {
        outline: colors.G500,
        background: colors.G500,
        fill: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        outline: colors.O500,
        background: colors.O500,
        fill: colors.O200,
        text: colors.O500,
      };
    case 'yellow':
      return {
        outline: colors.Y500,
        background: colors.Y500,
        fill: colors.Y200,
        text: colors.B500,
      };
    default:
      return {
        outline: colors.Y500,
        background: colors.Y500,
        fill: colors.Y200,
        text: colors.B500,
      };
  }
}
