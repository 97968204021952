import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './styles';

function QuickFacts({
  heading,
  colorTheme,
  tags,
}) {
  const tagList = tags.map((tag, i) => tag.text.split(', '));
  const themeStyles = styled.getThemeStyles(colorTheme);
  console.log(tags);
  return (
    <styled.Wrapper colorTheme={colorTheme}>
      <styled.Inner colorTheme={colorTheme}>
        <styled.Content>
          <styled.Heading level={200} colorTheme={colorTheme}>{heading}</styled.Heading>
          <styled.Tags itemscope itemtype={'https://schema.org/CreativeWork'}>
            <meta itemProp="keywords" content={tagList} />
            {tags.map((tag, i) => (
              <>
                <styled.Tag key={i} {...tag}>{tag.text}</styled.Tag>
                {i < tags.length - 1 && <string style={{ fontWeight: 'bold' }}> | </string>}
              </>
            ))}
          </styled.Tags>
        </styled.Content>
      </styled.Inner>
    </styled.Wrapper>
  );
}

QuickFacts.propTypes = {
  tags: PropTypes.shape({
    text: PropTypes.string.isRequired,
    bold: PropTypes.bool,
  }).isRequired,
  heading: PropTypes.string.isRequired,
  colorTheme: PropTypes.oneOf(['dark-blue', 'green', 'orange', 'dark-purple', 'white']),
};

QuickFacts.defaultProps = {
  colorTheme: 'dark-blue',
};

export default QuickFacts;
