import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { Body, Heading as UnstyledHeading } from '@components/type';
import UnstyledImage from 'gatsby-image';

export const Content = styled.div`
  position: relative;
  z-index: 1;

  ${mq.gtsm`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: ${ H_PADDING_MOBILE };
  `}
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 24px;
  color: ${(props) => props.outline && props.outline !== 'none' ?
    getThemeStyles(props.colorTheme).background :
    getThemeStyles(props.colorTheme).text};
  text-align: ${(props) => props.align};
  width: 100%;
  text-transform: uppercase;

  ${mq.gtsm`
    margin-bottom: 32px;
  `}
`;

export const Image = styled(UnstyledImage)`

  & + svg:last-of-type {
    //display: ${(props) => (props.type === 'staggered' ? 'block' : 'none')};
    fill: #fff;
    transform: scale(-1.1, .9) translateY(-17px);
  }

  img {
    filter: brightness(70%) contrast(120%);
  }
`;

export const Stories = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`;

export const Story = styled.div`
  flex: 1 1 ${props => props.size === 'small' ? '33%' : '50%'};
  position: relative;
`;

export const StoryData = styled.div`
  position: absolute;
  top: ${props => props.size === 'small' ? 'unset' : '16px'};
  bottom: ${props => props.size === 'small' ? '196px' : 'unset'};
  left: ${props => props.size === 'small' ? 'unset' : 0};
  right: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Link = styled(UnstyledHeading)`
  text-transform: uppercase;
  width: 100%;
  font-size: ${props => props.level === 500 ? '24px' : '42px'};
  margin-top: ${props => props.level === 500 ? '16px' : '10px'};
  padding-left: 32px;


  a {
    color: ${colors.N0};
    text-decoration: ${props => props.level === 500 ? 'underline' : 'inherit'};
  }
`;

export const Inner = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 18px ${H_PADDING_MOBILE};

  ${mq.gtmd`
    padding: 0 calc((100vw - 1280px) / 2);
  `}

  & > svg {
    position: absolute;
    top: 20%;
    right: -200px;
    left: -100px;
    width: calc(100% + 300px);
    z-index: 0;

    ${mq.gtsm`
      top: ${props => props.flipped ? 'auto' : '0'};
      bottom: ${props => props.flipped ? '0' : 'auto'};
      right: 50%;
      left: 0;
      width: 50%;
    `}
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;

  & > svg {
    z-index: 0;
  }

  & > svg:first-of-type {
    fill: ${(props) =>
      props.outline && props.outline !== 'none' ? 'none' : getThemeStyles(props.colorTheme).background};
    stroke: ${(props) =>
      props.outline === 'top' || props.outline === 'both'
        ? getThemeStyles(props.colorTheme).outline
        : 'none'};
    stroke-width: ${(props) =>
      props.outline === 'top' || props.outline === 'both' ? '3px' : 0};
    vertical-align: bottom;

    ${mq.gtsm`
      stroke-width: 1px;
    `}

    ${(props) =>
      props.outline && props.outline !== 'none' &&
      css`
        width: calc(100% + 6px);
        margin-right: -3px;
        margin-bottom: -3px;
        margin-left: -3px;
      `}
  }

  & > svg:last-of-type {
    fill: ${(props) =>
      props.outline && props.outline !== 'none' ? 'none' : getThemeStyles(props.colorTheme).background};
    stroke: ${(props) =>
      props.outline === 'bottom' || props.outline === 'both'
        ? getThemeStyles(props.colorTheme).outline
        : 'none'};
    stroke-width: ${(props) =>
      props.outline === 'bottom' || props.outline === 'both' ? '3px' : 0};
    transform: scale(-1, -1) translateY(1px);
    vertical-align: top;

    ${mq.gtsm`
      stroke-width: 1px;
    `}

    ${(props) =>
      props.outline &&
      css`
        width: calc(100% + 6px);
        margin-right: -3px;
        margin-top: -1px;
        margin-left: -3px;
      `}
  }
`;

export function getThemeStyles(theme) {
  switch (theme) {
    case 'white':
      return {
        outline: colors.B500,
        background: '#fff',
        swirl: colors.B100,
        fill: colors.B200,
        text: colors.B500,
      };
    case 'dark-blue':
      return {
        outline: colors.B500,
        background: colors.B500,
        fill: colors.B200,
        text: '#fff',
      };
    case 'dark-purple':
      return {
        outline: colors.P500,
        background: colors.P500,
        fill: colors.P200,
        text: '#fff',
      };
    case 'green':
      return {
        outline: colors.G100,
        background: colors.G100,
        fill: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        outline: colors.O100,
        background: colors.O100,
        fill: colors.O200,
        text: colors.O500,
      };
    default:
      return {
        outline: colors.Y100,
        background: colors.Y100,
        fill: colors.Y200,
        text: colors.B500,
      };
  }
}
