import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { Heading as UnstyledHeading } from '@components/type';
import UnstyledImage from 'gatsby-image';

export const Cards = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;

  ${mq.gtms`
    flex-direction: row;
  `}
`;

export const Card = styled.div`
  gap: 16px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  width: 30vw;
  height: 30vw;

  &:nth-child(2) {
    width: 40vw;
    height: 40vw;
    translate: 100px 0;
  }

  ${mq.gtms`
      &:first-child {
        width: 400px;
        height: 400px;
      }

      &:nth-child(2) {
        width: 500px;
        height: 500px;
        translate: 0 200px;
      }

      &:nth-child(3) {
        width: 350px;
        height: 350px;
      }
    `}

  // ${mq.ltms`
  //     &:first-child {
  //       width: 300px;
  //       height: 300px;
  //     }
  //
  //     &:nth-child(2) {
  //       width: 400px;
  //       height: 400px;
  //       translate: 0 150px;
  //     }
  //
  //     &:nth-child(3) {
  //       width: 250px;
  //       height: 250px;
  //     }
  // `}
`;

export const CardData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 24px;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  flex-direction: column;
  width: 100%;
`;

export const CardTitle = styled(UnstyledHeading)`
  color: ${colors.N0};
  text-transform: uppercase;
  text-align: center;
  width: 100%;

   ${mq.gtms`
     font-size: 54px;
   `}
`;

export const Link = styled(UnstyledHeading)`
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-top: 24px;

  a {
    color: ${colors.N0};
  }
`;

export const Image = styled(UnstyledImage)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100%;

  img {
    filter: brightness(70%) contrast(120%);
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;


  ${mq.gtsm`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: ${H_PADDING_MOBILE};
  `}
`;

export const Inner = styled.div`
  position: relative;
  z-index: 1;
  padding: 18px ${H_PADDING_MOBILE};

  ${mq.gtmd`
    display: flex;
    padding: 0 calc((100vw - 1280px) / 2);
  `}
  & > svg {
    position: absolute;
    top: 20%;
    right: -200px;
    left: -100px;
    width: calc(100% + 300px);
    z-index: 0;

    ${mq.gtsm`
      top: ${props => props.flipped ? 'auto' : '0'};
      bottom: ${props => props.flipped ? '0' : 'auto'};
      right: 50%;
      left: 0;
      width: 50%;
    `}
  }
`;

export const Wrapper = styled.div`
  ${mq.gtsm`
      margin-bottom: 200px;
    `}
  & > svg {
    z-index: 0;
  }

  & > svg:first-of-type {
    fill: ${(props) =>
      props.outline && props.outline !== 'none' ? 'none' : getThemeStyles(props.colorTheme).background};
    stroke: ${(props) =>
      props.outline === 'top' || props.outline === 'both'
        ? getThemeStyles(props.colorTheme).outline
        : 'none'};
    stroke-width: ${(props) =>
      props.outline === 'top' || props.outline === 'both' ? '3px' : 0};
    vertical-align: bottom;

    ${mq.gtsm`
      stroke-width: 1px;
    `}

    ${(props) =>
      props.outline && props.outline !== 'none' &&
      css`
        width: calc(100% + 6px);
        margin-right: -3px;
        margin-bottom: -3px;
        margin-left: -3px;
      `}
  }

  & > svg:last-of-type {
    fill: ${(props) =>
      props.outline && props.outline !== 'none' ? 'none' : getThemeStyles(props.colorTheme).background};
    stroke: ${(props) =>
      props.outline === 'bottom' || props.outline === 'both'
        ? getThemeStyles(props.colorTheme).outline
        : 'none'};
    stroke-width: ${(props) =>
      props.outline === 'bottom' || props.outline === 'both' ? '3px' : 0};
    transform: scale(-1, -1) translateY(1px);
    vertical-align: top;

    ${mq.gtsm`
      stroke-width: 1px;
    `}

    ${(props) =>
      props.outline &&
      css`
        width: calc(100% + 6px);
        margin-right: -3px;
        margin-top: -1px;
        margin-left: -3px;
      `}
  }
`;

export function getThemeStyles(theme) {
  switch (theme) {
    case 'white':
      return {
        outline: colors.B500,
        background: '#fff',
        swirl: colors.B100,
        fill: colors.B200,
        text: colors.B500,
      };
    case 'dark-blue':
      return {
        outline: colors.B500,
        background: colors.B500,
        fill: colors.B200,
        text: '#fff',
      };
    case 'dark-purple':
      return {
        outline: colors.P500,
        background: colors.P500,
        fill: colors.P200,
        text: '#fff',
      };
    case 'green':
      return {
        outline: colors.G100,
        background: colors.G100,
        fill: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        outline: colors.O100,
        background: colors.O100,
        fill: colors.O200,
        text: colors.O500,
      };
    default:
      return {
        outline: colors.Y100,
        background: colors.Y100,
        fill: colors.Y200,
        text: colors.B500,
      };
  }
}
