import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './styles';
import get from 'lodash/get';
import { Link } from 'gatsby';

function SimpleCards(props) {
  const {
    heading,
    colorTheme,
    cards,
  } = props;
  const themeStyles = styled.getThemeStyles(colorTheme);
  console.log(props);
  return (
    <styled.Wrapper>
      <styled.Inner>
        <styled.Content>
          <styled.Heading level={200} colorTheme={colorTheme}>{heading}</styled.Heading>
          <styled.Cards>{cards.map(card => (
            <styled.Card colorTheme={card.colorTheme} transparent={card.isTransparent}>
              {card.image ? <styled.Image
                edge={0} altText={card.image.altText}
                fluid={get(card, 'image.asset.fluid')}
              /> : null}
              <styled.CardData>
                <styled.CardTitle level={300}>{card.title}</styled.CardTitle>
                {card.route ?
                  <styled.Link level={500}><Link to={get(card, 'route.slug.current') || card.link}>{card.label} ></Link>
                  </styled.Link> : null}
              </styled.CardData>
            </styled.Card>
          ))}
          </styled.Cards>
        </styled.Content>
      </styled.Inner>
    </styled.Wrapper>
  );
}

SimpleCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    isTransparent: PropTypes.bool,
    colorTheme: PropTypes.oneOf(['dark-blue', 'green', 'orange', 'dark-purple', 'white']),
    label: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
    link: PropTypes.string,
    route: PropTypes.shape({
      slug: PropTypes.shape({
        current: PropTypes.string,
      }),
    }),
  })),
  heading: PropTypes.string.isRequired,
  headingAlignment: PropTypes.oneOf(['left', 'center', 'right']),
  colorTheme: PropTypes.oneOf(['dark-blue', 'green', 'orange', 'dark-purple', 'white']),
};

SimpleCards.defaultProps = {
  colorTheme: 'dark-blue',
};

export default SimpleCards;
