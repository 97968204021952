import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { breakpoints } from '@utils/responsive';
import { Play } from '@components/Icon';
import Image from '@components/Image';
import Swiper from '@components/Swiper';
import { Caption, Heading } from '@components/type';
import { BlobTwo, RoughOne, RoughThree } from '@components/vector';
import * as styled from './styles';

class StudentStories extends Component {
  constructor(props) {
    super(props);

    console.log(props);

    this.itemRefs = {
      desktop: Array(props.items.length)
        .fill()
        .map(_ => React.createRef()),
      mobile: Array(props.items.length)
        .fill()
        .map(_ => React.createRef()),
    };
  }

  state = {
    playing: null,
    previewing: null,
  };

  handleEnd = () => {
    this.setState({ playing: null });
  };

  handlePlay = (key, idx) => {
    const { playing } = this.state;

    if (playing !== null) {
      this.itemRefs[key][playing.idx].current.pause();
      this.itemRefs[key][playing.idx].current.currentTime = 0;
      this.itemRefs[key][playing.idx].current.removeEventListener('ended', this.handleEnd);
    }

    this.itemRefs[key][idx].current.currentTime = 0;
    this.itemRefs[key][idx].current.muted = false;
    this.itemRefs[key][idx].current.play();
    this.itemRefs[key][idx].current.addEventListener('ended', this.handleEnd);
    this.setState({ playing: { key, idx }, previewing: null });
  };

  handlePreview = (idx) => {
    if (idx === this.state.previewing || idx === get(this.state, 'playing.idx')) {
      return;
    }

    const updateState = ({ previewing }) => {
      if (previewing) {
        this.itemRefs.desktop[previewing].current.pause();
        this.itemRefs.desktop[previewing].current.currentTime = 0;
        this.itemRefs.desktop[previewing].current.removeEventListener(
          'timeupdate',
          this.handleReset,
        );
      }

      return { previewing: idx };
    };

    const postUpdate = () => {
      this.itemRefs.desktop[idx].current.muted = true;
      this.itemRefs.desktop[idx].current.currentTime = 3;
      this.itemRefs.desktop[idx].current.play();

      this.itemRefs.desktop[idx].current.addEventListener(
        'timeupdate',
        this.handleReset,
      );
    };

    this.setState(updateState, postUpdate);
  };

  handleReset = () => {
    const { playing, previewing } = this.state;

    if (!previewing) {
      return;
    }

    if (this.itemRefs.desktop[previewing].current) {
      const item = this.itemRefs.desktop[previewing].current;

      if (get(playing, 'idx') !== previewing) {
        if (item.currentTime >= 6.5) {
          item.currentTime = 3;
        }
      }
    }
  };

  componentWillUnmount() {
    const { playing, previewing } = this.state;

    if (get(playing, 'key')) {
      this.itemRefs[playing.key][playing.idx].current.removeEventListener(
        'ended',
        this.handleEnd,
      );
    }

    if (previewing !== null) {
      this.itemRefs.desktop[previewing].current.removeEventListener(
        'timeupdate',
        this.handleReset,
      );
    }
  }

  render() {
    const { heading, items, colorTheme, type } = this.props;
    const { previewing, playing } = this.state;

    const params = {
      slidesPerView: 1.5,
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24,
      spaceBetween: 32,
      breakpoints: {
        [breakpoints.mobile]: {
          slidesPerView: 2.5,
        },
      },
    };

    return (
      <styled.Wrapper
        className="block--student-stories"
        colorTheme={colorTheme}
        type={type}
      >
        <RoughThree />
        <styled.Heading level={100} colorTheme={colorTheme}>
          {heading}
        </styled.Heading>
        <styled.Body colorTheme={colorTheme} type={type}>
          <styled.Items>
            {items.slice(0, 4).map((item, idx) => (
              <styled.Item
                className="card--student-story"
                index={idx}
                key={item._key}
                type={type}
              >
                <styled.ImageWrapper
                  onMouseEnter={() => {
                    this.handlePreview(idx);
                  }}
                  onMouseLeave={() => {
                    this.setState({ previewing: null });
                  }}
                >
                  {get(item, 'student.image.asset.fluid') && (
                    <Image
                      fluid={item.student.image.asset.fluid}
                      ratio={3 / 2}
                    />
                  )}
                  <styled.Video
                    controls={get(playing, 'idx') === idx}
                    controlsList="nodownload nofullscreen noremoteplayback"
                    disablePictureInPicture
                    ref={this.itemRefs.desktop[idx]}
                    preload="none"
                  >
                    <source
                      src={get(item, 'video.asset.url')}
                      type="video/mp4"
                    />
                  </styled.Video>
                  {get(item, 'student.image.asset.fluid') && (
                    <styled.Image
                      fluid={item.student.image.asset.fluid}
                      ratio={3 / 2}
                      isHidden={get(playing, 'idx') === idx || previewing === idx}
                    />
                  )}
                  <styled.Info isVisible={previewing === idx}>
                    <styled.PlayButton
                      aria-label="Play"
                      onClick={() => {
                        this.handlePlay('desktop', idx);
                      }}
                      colorTheme={colorTheme}
                    >
                      <BlobTwo />
                      <Play />
                    </styled.PlayButton>
                    {item.question ? (
                      <>
                        <styled.Question levels={[500, 400]}>
                          {item.question}
                        </styled.Question>
                        <Caption>
                          {get(item, 'student.name')}
                        </Caption>
                      </>
                    ) : (
                      <Heading levels={[400, 300]}>
                        {get(item, 'student.name')}
                      </Heading>
                    )}
                  </styled.Info>
                </styled.ImageWrapper>
              </styled.Item>
            ))}
          </styled.Items>
          <styled.MobileItems>
            <styled.GlobalStyle />
            <Swiper params={params}>
              {items.map((item, idx) => (
                <styled.Item
                  className={`card--student-story ${type}`}
                  key={item._key}
                >
                  <styled.ImageWrapper isPlaying={get(playing, 'idx') === idx}>
                    <styled.Video
                      controls
                      controlsList="nodownload noremoteplayback"
                      disablePictureInPicture
                      ref={this.itemRefs.mobile[idx]}
                      preload="none"
                    >
                      <source
                        src={get(item, 'video.asset.url')}
                        type="video/mp4"
                      />
                    </styled.Video>
                    {get(item, 'student.image.asset.fluid') && (
                      <Image
                        fluid={item.student.image.asset.fluid}
                        ratio={3 / 2}
                      />
                    )}
                    <styled.Info isVisible={get(playing, 'idx') !== idx}>
                      <styled.PlayButton
                        aria-label="Play"
                        onClick={() => {
                          this.handlePlay('mobile', idx);
                        }}
                        colorTheme={colorTheme}
                      >
                        <BlobTwo />
                        <Play />
                      </styled.PlayButton>
                      {item.question ? (
                        <>
                          <styled.Question levels={[500, 400]}>
                            {item.question}
                          </styled.Question>
                          <Caption>
                            {get(item, 'student.name')}
                          </Caption>
                        </>
                      ) : (
                        <Heading levels={[400, 300]}>
                          {get(item, 'student.name')}
                        </Heading>
                      )}
                    </styled.Info>
                  </styled.ImageWrapper>
                </styled.Item>
              ))}
            </Swiper>
          </styled.MobileItems>
        </styled.Body>
        <RoughOne />
      </styled.Wrapper>
    );
  }
}

StudentStories.propTypes = {
  items: PropTypes.array.isRequired,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
  type: PropTypes.oneOf(['plain', 'staggered']),
};

StudentStories.defaultProps = {
  colorTheme: 'orange',
  type: 'plain',
};

export default StudentStories;
