import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './styles';
import { Link } from 'gatsby';
import { RoughOne } from '../../vector';

function Stories2Sides(props) {
  const {
    heading,
    colorTheme,
    storyLarge,
    storySmall,
    headingAlignment,
  } = props;
  console.log(props);
  const themeStyles = styled.getThemeStyles(colorTheme);

  function getStoryCard(story, size) {
    return (
      <styled.Story size={size}>
        <styled.Image fluid={story.image.asset.fluid} />
        <RoughOne />
        <styled.StoryData size={size}>
          <styled.Link level={300}><Link to={`/stories/${story.slug.current}`}>{story.title}</Link></styled.Link>
          <styled.Link level={500}><Link to={`/stories${story.category.slug.current}`}>{story.category.title} ></Link></styled.Link>
        </styled.StoryData>
      </styled.Story>
    );
  }

  return (
    <styled.Wrapper colorTheme={colorTheme}>
      <styled.Inner colorTheme={colorTheme}>
        <styled.Content>
          <styled.Heading level={200} colorTheme={colorTheme} align={headingAlignment}>{heading}</styled.Heading>
          <styled.Stories>
            {getStoryCard(storySmall, 'small')}
            {getStoryCard(storyLarge, 'large')}
          </styled.Stories>
        </styled.Content>
      </styled.Inner>
    </styled.Wrapper>
  );
}

Stories2Sides.propTypes = {
  storyLarge: PropTypes.object,
  storySmall: PropTypes.object,
  heading: PropTypes.string.isRequired,
  headingAlignment: PropTypes.oneOf(['left', 'center', 'right']),
  colorTheme: PropTypes.oneOf(['dark-blue', 'green', 'orange', 'dark-purple', 'white']),
};

Stories2Sides.defaultProps = {
  colorTheme: 'dark-blue',
};

export default Stories2Sides;
